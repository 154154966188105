import {
  Box,
  Flex,
  Text,
  Heading,
  FormLabel,
  FormControl,
  Stack,
  Container,
  FormHelperText,
  Input,
  Textarea,
  FormErrorMessage,
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { sendContactMessage } from 'src/clients/api/contact';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { contactFormSchema, ContactFormSchemaPayload } from './validation';

export interface ContactProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Contact: FC<ContactProps> = () => {
  const [state, setState] = useAsyncState();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ContactFormSchemaPayload>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(contactFormSchema),
  });

  console.log(errors);

  const onSubmit = handleSubmit(async (values: ContactFormSchemaPayload) => {
    setState({
      status: 'loading',
    });

    try {
      setState({
        status: 'loading',
      });

      const result = await sendContactMessage(values);

      if (result.status === 'error') {
        throw new Error(result.value.errors[0].message);
      }

      setState({
        status: 'success',
        message: 'Message sent successfully',
      });
    } catch (e) {
      setState({
        status: 'error',
        message: e.message ?? 'There was an error logging you in.',
      });
    }
  });

  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/food_processing.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Contact us
          </Heading>
        )}
        subtitle={() => (
          <Text fontSize="lg" mt="5">
            To request additional information about the Smart Water Navigator,
            please complete the form below and a representative will contact
            you.
          </Text>
        )}
      />
      <Flex flex="1 1 auto" w="full">
        <Container maxW={1920 / 3} py="20">
          {state.status === 'success' ? (
            <Stack spacing="5">
              <Alert status="success" variant="left-accent">
                <AlertIcon />
                <AlertTitle flexDir="row">Success!</AlertTitle>
                <AlertDescription>
                  {state.message ?? 'Message sent successfully'}
                </AlertDescription>
              </Alert>
              <Flex justifyContent="flex-end" w="full">
                <Button variant="link" as={Link} to="/">
                  Return Home
                </Button>
              </Flex>
            </Stack>
          ) : (
            <>
              {state.status === 'error' ? (
                <Alert status="error" variant="left-accent" mb="10">
                  <AlertIcon />
                  <AlertTitle>Error!</AlertTitle>
                  <AlertDescription>
                    {state.message ?? 'An error was encountered.'}
                  </AlertDescription>
                </Alert>
              ) : null}
              <Stack
                direction="column"
                spacing="24px"
                as="form"
                onSubmit={onSubmit}
              >
                <Flex direction="column">
                <FormControl isRequired>
                    <FormLabel size="md" textTransform="uppercase">
                      Name
                    </FormLabel>
                  </FormControl>
                  <Stack direction={{ base: 'column', lg: 'row' }} w="100%">
                    <Box flex={{ base: '0 0 100%', lg: '0 0 50%' }}>
                      <FormControl isRequired isInvalid={!!errors.firstName}>
                        <Input
                          name="firstName"
                          ref={register}
                          border="2px solid"
                          borderColor="#B7B7B7"
                        />
                        <FormHelperText>First</FormHelperText>
                        <FormErrorMessage justifyContent="flex-end">
                          <Text as="span" size="sm">
                            {errors.firstName?.message}
                          </Text>
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box flex={{ base: '0 0 100%', lg: '0 0 50%' }}>
                      <FormControl isRequired isInvalid={!!errors.lastName}>
                        <Input
                          name="lastName"
                          ref={register}
                          border="2px solid"
                          borderColor="#B7B7B7"
                        />
                        <FormHelperText>Last</FormHelperText>
                        <FormErrorMessage justifyContent="flex-end">
                          <Text as="span" size="sm">
                            {errors.lastName?.message}
                          </Text>
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Stack>
                </Flex>
                <FormControl isInvalid={!!errors.company}>
                  <FormLabel
                    htmlFor="company"
                    size="md"
                    textTransform="uppercase"
                  >
                    Company
                  </FormLabel>
                  <Input
                    name="company"
                    ref={register}
                    border="2px solid"
                    borderColor="#B7B7B7"
                  />
                  <FormErrorMessage justifyContent="flex-end">
                    <Text as="span" size="sm">
                      {errors.company?.message}
                    </Text>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.city}>
                  <FormLabel htmlFor="city" size="md" textTransform="uppercase">
                    City
                  </FormLabel>
                  <Input
                    name="city"
                    ref={register}
                    border="2px solid"
                    borderColor="#B7B7B7"
                  />
                  <FormErrorMessage justifyContent="flex-end">
                    <Text as="span" size="sm">
                      {errors.city?.message}
                    </Text>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.country}>
                  <FormLabel
                    htmlFor="country"
                    size="md"
                    textTransform="uppercase"
                  >
                    Country
                  </FormLabel>
                  <Input
                    name="country"
                    ref={register}
                    border="2px solid"
                    borderColor="#B7B7B7"
                  />
                  <FormErrorMessage justifyContent="flex-end">
                    <Text as="span" size="sm">
                      {errors.country?.message}
                    </Text>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.email}>
                  <FormLabel
                    htmlFor="email"
                    size="md"
                    textTransform="uppercase"
                  >
                    Email Address
                  </FormLabel>
                  <Input
                    name="email"
                    ref={register}
                    border="2px solid"
                    borderColor="#B7B7B7"
                  />
                  <FormErrorMessage justifyContent="flex-end">
                    <Text as="span" size="sm">
                      {errors.email?.message}
                    </Text>
                  </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.comments}>
                  <FormLabel
                    htmlFor="details"
                    size="md"
                    textTransform="uppercase"
                  >
                    Question or Comment
                  </FormLabel>
                  <Textarea
                    rows={8}
                    border="2px solid"
                    borderColor="#B7B7B7"
                    padding="10px 15px"
                    name="comments"
                    ref={register}
                    isInvalid={!!errors.comments}
                  />
                  <FormErrorMessage justifyContent="flex-end">
                    <Text as="span" size="sm">
                      {errors.comments?.message}
                    </Text>
                  </FormErrorMessage>
                </FormControl>

                <Flex justifyContent="flex-end" w="full">
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={state.status === 'loading'}
                  >
                    Submit
                  </Button>
                </Flex>
              </Stack>
            </>
          )}
        </Container>
      </Flex>
      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
