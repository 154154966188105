import React, { FC } from 'react';

export interface UpdateCookiePrefButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  solid?: boolean;
}

export const UpdateCookiePrefButton: FC<UpdateCookiePrefButtonProps> = ({
  ...props
}) => {

    const reloadOTBanner = () => {
        if ((window as any).OneTrust != null) {
            // Check if OneTrust is already initialized
            if ((window as any).OneTrust.IsInitialized) {
                (window as any).OneTrust.LoadBanner();
            } else {
                console.log("Initializing OneTrust");
                (window as any).OneTrust.Init();
                (window as any).OneTrust.OnConsentChanged(() => {
                    (window as any).OneTrust.LoadBanner();
                });
            }
            (window as any).OneTrust.ToggleInfoDisplay();
        }
    }

  return (
    <button onClick={reloadOTBanner}>
      Update Cookie Preferences
    </button>
  );
};

UpdateCookiePrefButton.displayName = 'UpdateCookiePrefButton';
