export function checkConsentAndUpdateContent(): void {
  const performanceCookie = 'C0002';
  const functionalCookie = 'C0003';
  const targetingCookie = 'C0004';
  const socialCookie = 'C0005';
  let otIniGrps: any;

  // Get initial OnetrustActiveGroups ids
  if (typeof (window as any).OptanonWrapperCount === 'undefined') {
    otGetInitialGrps();
  }

  // console.log('on load',(window as any).OnetrustActiveGroups)

  runScriptForOt(runOnPageLoadAndSaveChangs);

  function runScriptForOt(functionName: () => void) {
    setTimeout(functionName, 1000);
  }

  function runOnPageLoadAndSaveChangs() {
    otDeleteCookie(otIniGrps);
    diableScriptByEcolabOnPageLoad();
  }

  function focusTrap(focusTrapId: string) {
    const focusTrapDiv = document.getElementById(focusTrapId);
    if (focusTrapDiv) {
      const focusableElements = focusTrapDiv.querySelectorAll(
        'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const focusable = Array.from(focusableElements) as HTMLElement[];
      const firstElement = focusable[0];
      const lastElement = focusable[focusable.length - 1];

      function trapFocus(event: KeyboardEvent) {
        if (event.key === 'Tab') {
          // Shift + Tab
          if (event.shiftKey) {
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            // Tab
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      }

      focusTrapDiv.addEventListener('keydown', trapFocus);
    }
  }

  function diableScriptByEcolabOnPageLoad() {
    if (!(window as any).OptanonActiveGroups.includes(performanceCookie)) {
      // Custom code for disabling
    }
  }

  function ecoDeleteCookie(name: string, domainName?: string) {
    document.cookie = `${name}=; Max-Age=-99999999; Path=/;Domain=${domainName}`;
    document.cookie = `${name}=; Max-Age=-99999999; Path=/;Domain=.ecolab.com`;
  }

  function ecoDeleteLocalStorage(name: string) {
    localStorage.removeItem(name);
  }

  function otGetInitialGrps() {
    (window as any).OptanonWrapperCount = '';
    otIniGrps = (window as any).OnetrustActiveGroups;
  }

  function otDeleteCookie(iniOptGrpId: any) {
    const otDomainGrps = JSON.parse(
      JSON.stringify((window as any).Optanon.GetDomainData().Groups)
    );
    // console.log(iniOptGrpId)
    const optanonActiveGroups = (window as any).OptanonActiveGroups ?? [];
    console.log(optanonActiveGroups);
    const ytCookieActive = optanonActiveGroups.includes('C0007');
    localStorage.setItem('ytCookieActive', JSON.stringify(ytCookieActive));

    if (iniOptGrpId !== (window as any).OptanonActiveGroups){
      window.location.reload();
    }

    const otDeletedGrpIds = getMissingGroupIds();

    if (otDeletedGrpIds.length !== 0 && otDomainGrps.length !== 0) {
      for (const group of otDomainGrps) {
        if (
          group.CustomGroupId &&
          otDeletedGrpIds.includes(group.CustomGroupId)
        ) {
          for (const cookie of group.Cookies) {
            // console.log(cookie.Name)
            eraseCookie(cookie.Name);
          }
        }

        for (const host of group.Hosts) {
          if (
            otDeletedGrpIds.includes(host.HostId) &&
            host.Cookies.length !== 0
          ) {
            for (const cookie of host.Cookies) {
              // console.log(cookie.Name)
              eraseCookie(cookie.Name);
            }
          }
        }
      }
    }
    otGetInitialGrps(); // Reassign new group ids
  }

  function getMissingGroupIds() {
    const missingGroupIds: string[] = [];
    const currentGroupIds = (window as any).OptanonActiveGroups;

    if (!currentGroupIds.includes('C0001')) missingGroupIds.push('C0001');
    if (!currentGroupIds.includes(performanceCookie))
      missingGroupIds.push(performanceCookie);
    if (!currentGroupIds.includes(functionalCookie))
      missingGroupIds.push(functionalCookie);
    if (!currentGroupIds.includes(targetingCookie))
      missingGroupIds.push(targetingCookie);
    if (!currentGroupIds.includes(socialCookie))
      missingGroupIds.push(socialCookie);

    return missingGroupIds;
  }

  function eraseCookie(name: string) {
    const domainName = window.location.hostname;

    ecoDeleteCookie(name, domainName);
    document.cookie = `${name}=; Max-Age=-99999999; Path=/;`;

    localStorage.removeItem(name);

    const pathArray = window.location.pathname.split('/');
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i]) {
        const currentPath = pathArray.slice(0, i + 1).join('/');
        document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath};Domain=${domainName}`;
        document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath};`;
      }
    }
  }
}
