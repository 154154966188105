import React from 'react';
import ReactDOM from 'react-dom';
import { AppProviders } from './components/AppProviders';
import { PreventIframe } from './components/PreventIframe';
import './index.css';
import { App } from './routes';
import { checkConsentAndUpdateContent } from 'src/utils/cookieConsent';
import { BrowserRouter } from 'react-router-dom';

declare global {
  interface Window {
    checkConsentAndUpdateContent: () => void;
    OnetrustActiveGroups: string[];
  }
}

window.checkConsentAndUpdateContent = checkConsentAndUpdateContent;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProviders>
        <PreventIframe />
        <App />
      </AppProviders>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
