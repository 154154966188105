import { Box, Text } from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { Pressable } from '../Pressable';
import styles from './SiteFooter.module.css';
import { UpdateCookiePrefButton } from '../UpdateCookiePrefButton';

const cx = classnames.bind(styles);

export interface SiteFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  solid?: boolean;
}

export const SiteFooter: FC<SiteFooterProps> = ({
  children,
  className,
  solid = true,
  ...props
}) => {
  return (
    <div className={cx('siteFooterGroup', className, { solid })} {...props}>
      <div className={cx('siteFooter')}>
        <Box className={cx('section')} textAlign="left">
          <Text color="white" size="sm">
            Developed by Ecolab in collaboration with S&P Global Sustainable1
          </Text>
        </Box>

        <Box className={cx('section')} textAlign="center">
          <Pressable to="/terms-of-service" className={cx('footerLink')}>
            <Text color="white" size="sm">
              Terms of Service
            </Text>
          </Pressable>

          <Pressable
            to="https://www.ecolab.com/privacy-policy"
            className={cx('footerLink')}
          >
            <Text color="white" size="sm">
              Privacy Notice
            </Text>
          </Pressable>

          <Pressable to="/cookies" className={cx('footerLink')}>
            <Text color="white" size="sm">
              Cookies
            </Text>
          </Pressable>

          <Pressable to="/contact" className={cx('footerLink')}>
            <Text color="white" size="sm">
              Contact
            </Text>
          </Pressable>
        </Box>

        <Box className={cx('section')} textAlign="right">
          <div>
            <Text color="white" size="sm">
              &copy; {new Date().getFullYear()} Smart Water Navigator, All
              Rights Reserved
            </Text>
          </div>
          <div className={cx('button')}>
            <UpdateCookiePrefButton />
          </div>
        </Box>
      </div>
    </div>
  );
};

SiteFooter.displayName = 'SiteFooter';
