import {
  Container,
  Heading,
  ListItem,
  Link as ChakraLink,
  Stack,
  Text,
  OrderedList,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { UpdateCookiePrefButton } from 'src/components/UpdateCookiePrefButton';
import styles from './Cookies.module.css';

const cx = classnames.bind(styles);

export interface CookiesProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Cookies: FC<CookiesProps> = () => {
  const listItemStyle = { paddingTop: 10 };
  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/water-wind-energy.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Cookies
          </Heading>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(5vh)' }}
        p="20px"
        pb="40px"
      >
        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>Cookie Notice</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              This Cookie Notice explains how Smart Water Navigator uses cookies
              and similar technologies (collectively, “Cookies”) to recognize
              you when you visit our website. It explains what Cookie
              technologies are and why we use them.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>What are Cookies?</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Cookies are small data files that are placed or accessed on your
              device when you visit websites or other digital properties.
              Digital property owners can use Cookies for a variety of reasons,
              such as enabling their digital properties to work (or work more
              efficiently), providing personalized content, and advertising, and
              creating analytics. Cookies set by the digital property owner are
              called “first-party cookies.” Only the digital property owner can
              access the first-party Cookies it sets. Cookies set by parties
              other than the digital property owner are called “third-party
              Cookies.” Third-party Cookies enable third-party features or
              functionality to be provided on or through the digital property
              (such as advertising, interactive content, and social sharing).
              The parties that set these third-party Cookies can recognize your
              device both when it visits the digital property in question and
              when it visits other digital properties that have partnered with
              them.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>Why do we use Cookies on Our Website?</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <OrderedList pl="10">
              <ListItem style={listItemStyle}>
                <u>Strictly Necessary Cookies</u> are necessary for the website
                to function and cannot be switched off in our systems. They
                enable basic features of the website and for security purposes
                related to user interaction. They are usually only set in
                response to user actions which may include a request for
                services, such as setting user privacy preferences, logging in
                or filling in forms.
              </ListItem>
              <ListItem style={listItemStyle}>
                <u>Performance Cookies</u> allow us to count visits and traffic
                sources so we can measure and improve the performance of our
                website. They help us to know which pages are the most and least
                popular and see how visitors move around the website. All
                information these cookies collect is aggregated.
              </ListItem>
              <ListItem style={listItemStyle}>
                <u>Functional Cookies</u> enable the website to provide enhanced
                functionality and personalization. They may be set by us or by
                third party providers whose services we have added to our pages.
              </ListItem>
              <ListItem style={listItemStyle}>
                <u>Advertising Cookies</u> allow us to better understand your
                interests and show you ads that are more relevant to you on our
                website. These Cookies are used to track users over time and
                across websites, including by third parties. For example, we use
                Advertising Cookies to show our advertisements on third-party
                websites based on your user profile and preferences, and to
                determine when you visit our website directly from clicking on
                such advertisements. These Cookies may also reveal how many
                visitors with specific user profiles and preferences have seen
                or clicked on our advertisements to optimize our advertising
                campaigns.
              </ListItem>
              <ListItem style={listItemStyle}>
                <u>Embedded YouTube Video Cookies</u> are advertising and
                performance cookies associated directly with YouTube videos.
                YouTube collects user data through videos embedded in websites.
                YouTube videos will not display properly without these cookies.
                You will be provided with links to watch our videos on YouTube,
                if you do not wish to enable these cookies.
              </ListItem>
            </OrderedList>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>Where can you get further information?</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              You can change your cookie preferences at any time by using the
              Cookie Preferences center on the Website.
            </Text>
          </Container>
          <Container>
            <div className={cx('button')}>
              <UpdateCookiePrefButton />
            </div>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              If you have questions about our use of Cookies or other
              technologies, please contact us at{' '}
              <ChakraLink
                isExternal
                textDecoration="underline"
                href="mailto:dataprivacy@ecolab.com"
              >
                dataprivacy@ecolab.com
              </ChakraLink>
              {'.'}
            </Text>
          </Container>
        </Stack>
      </Stack>
      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
