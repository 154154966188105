export const manualUrlDecode = (str: string): string => {
  return str.replace(/%([0-9A-F]{2})/g, (match, p1) => {
    return String.fromCharCode(parseInt(p1, 16));
  }).replace(/\+/g, ' ');
};

export const getCookieValue = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue) {
      return cookieValue.split(';')[0];
    }
  }
  return null;
};

export const checkCookieValue = (cookieName: string, checkValue: string): boolean => {
  const cookieValue = getCookieValue(cookieName);
  if (cookieValue) {
    const decodedCookieValue = manualUrlDecode(cookieValue);
    return decodedCookieValue.includes(checkValue);
  }
  return false;
};